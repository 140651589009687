<template>
    <div class="button-container">
      <button class="button-button button ButtonSmall">
        {{ button }}
      </button>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ButtonStyle1',
    props: {
      button: {
        type: String,
        default: 'Button',
      },
    },
  }
  </script>
  
  <style scoped>
  .button-container {
    display: flex;
    position: relative;
  }
  .button-button {
    color: var(--dl-color-gray-black);
    outline: none;
    background: linear-gradient( 310deg, #e6b873, #ffdbac);
    box-shadow: 0 4px 7px -1px rgb(0 0 0 / 11%), 0 2px 4px -1px rgb(0 0 0 / 7%);
    transition: all .15s ease-in;
    padding-top: var(--dl-space-space-triplequarterunit);
    border-width: 0px;
    padding-left: var(--dl-space-space-unitandahalfunit);
    border-radius: 1.875rem;
    padding-right: var(--dl-space-space-unitandahalfunit);
    padding-bottom: var(--dl-space-space-triplequarterunit);
  }
  .button-button:hover {
    cursor: pointer;
    transform: scale(1.02);
  }
  </style>
  