<template>

  <HomeBody />

</template>

<script>

import HomeBody from '@/components/HomeBody.vue'

export default {
  name: 'HomePage',
  components: {
    HomeBody
  }
}
</script>