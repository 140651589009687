<template>
  <!-- ***** Preloader Start ***** -->
  <div id="preloader">
    <div class="jumper">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
  <!-- ***** Preloader End ***** -->


  <!-- ***** Header Area Start ***** -->
  <header class="header-area header-sticky">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <nav class="main-nav">
            <!-- ***** Logo Start ***** -->
            <div class="logo">
              Genus Coffea
            </div>
            <!-- ***** Logo End ***** -->
            <!-- ***** Menu Start ***** -->
            <ul class="nav">
              <li><a href="#welcome" class="active">Home</a></li>
              <li><a href="#types">Coffee Types</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#testimonials">Brewtiful Reviews</a></li>
              <li><a href="#contact-us">Contact Us</a></li>
            </ul>
            <a class='menu-trigger'>
              <span>Menu</span>
            </a>
            <!-- ***** Menu End ***** -->
          </nav>
        </div>
      </div>
    </div>
  </header>
  <!-- ***** Header Area End ***** -->

  <!-- ***** Welcome Area Start ***** -->
  <div class="welcome-area" id="welcome">
    <div class="home-hero">
      <div class="home-container01">
        <div class="home-card">
          <h1 class="home-text HeadingOne">Elevate Your Coffee Experience.</h1>
          <h1 class="home-text01 HeadingOne">Choose the best</h1>
          <span class="home-text02 Lead">
            <span>
              Indulge in the brilliance of Genus Coffea&apos;s exquisite blends.
            </span>
            <span>Experience the perfect fusion with every sip.</span>
          </span>
          <div class="home-container02">
            <div class="home-container03">
              <a href="#contact-us"><ButtonStyle1 button="Contact Us"></ButtonStyle1></a>
            </div>
            <a href="#contact-us"><ButtonStyle2 button="Take A Taste"></ButtonStyle2></a>
          </div>
        </div>
      </div>
    </div>
    <img alt="image" src="/coffe-page1.jpg" class="home-image04" />
  </div>
  <!-- ***** Welcome Area End ***** -->



  <!-- ***** Features Big Item Start ***** -->


  <section class="mini" id="types">
    <div class="container">
      <!-- First row -->
      <div class="row">
        <div class="col">
          <div class="container1">
            <div class="box1">
              <img alt="image" src="/coffee1.jpeg" class="glass_image" />

              <span class="title1">Arabica Malabar</span>
              <div>
                <p> Cultivated in the high ranges of Kerala, this single-origin Arabica powder boasts a complex flavor
                  profile. Sun-dried beans unlock notes of citrus, honey, and a hint of spice, making it a bright and
                  invigorating cup perfect for savoring the essence of Kerala.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="container1">
            <div class="box1">
              <img alt="image" src="/coffee2.jpeg" class="glass_image" />
              <span class="title1">Robusta Wayanad</span>
              <div>

                <p>Grown in the Wayanad district, this Robusta powder delivers a bold and intense coffee experience. The
                  rich, earthy flavor with a satisfying kick awakens your senses. Robusta Wayanad is ideal for those who
                  crave a strong, no-nonsense cup to jumpstart their day.</p>

              </div>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="container1">
            <div class="box1">
              <img alt="image" src="/coffee3.jpeg" class="glass_image" />
              <span class="title1">Monsoon Malabar</span>
              <div>
                <p>Experience the unique magic of Monsoon Malabar. These distinctive peaberry beans, the single round
                  seeds of the coffee cherry, undergo a monsooning process where they are exposed to the southwest
                  monsoon winds of Kerala.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </section>


  <section class="section padding-top-0 padding-bottom-0 about" id="about">
    <div class="container">
      <div class="row">
        <div class="col" style="margin: 20px 0;">

          <div class="center-heading">
            <h2 class="section-title">Genus Coffea: Unveiling the World's Finest Coffee Beans</h2>
          </div>
          <div class="center-text">
            <p>
              At Genus Coffea, we are passionate about crafting exceptional coffee experiences. We are a dedicated
              coffee purveyor, meticulously sourcing the world's finest coffee beans from renowned origins. Our
              commitment extends beyond cultivation, encompassing meticulous roasting techniques that unlock the unique
              flavor profiles of each bean.
            </p>
            <p>
              We believe that coffee is more than just a beverage; it's a journey of discovery. With Genus Coffea, you
              can embark on a sensory adventure, savoring the nuanced aromas and flavors that distinguish our premium
              coffee powders.
            </p>
          </div>
          <div class="center-heading">
            <h2 class="section-title">Our Mission:</h2>
          </div>
          <div class="center-text">
            <p>
              Our mission is to deliver unparalleled coffee experiences by providing high-quality, ethically sourced
              coffee powders that tantalize your taste buds and invigorate your senses.
            </p>
          </div>

        </div>
        <div class="col">
          <img src="about.jpeg" class="about_img" />
        </div>
      </div>
    </div>
  </section>

  <!-- ***** Testimonials Start ***** -->
  <section class="section test" id="testimonials">
    <div class="container">
      <!-- ***** Section Title Start ***** -->
      <div class="row">
        <div class="col-lg-12">
          <div class="center-heading">
            <h2 class="section-title">What do they say?</h2>
          </div>
        </div>
        <div class="offset-lg-3 col-lg-6">
          <div class="center-text">
            <p>We hear you! Thanks for the feedback. We're always working to improve.</p>
          </div>
        </div>
      </div>
      <!-- ***** Section Title End ***** -->

      <div class="row">
        <!-- ***** Testimonials Item Start ***** -->
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="team-item">
            <div class="team-content">
              <i><img src="4.png" alt=""></i>
              <p>Mornings just got better with Genus Coffea's 'South Indian Filter Blend.' Strong, aromatic, and wakes
                me up instantly. Plus, love the eco-friendly packaging!</p>
              <div class="user-image">
                <img src="dp1.png" style="width: 60px; height: 60px;" alt="">
              </div>
              <div class="team-info">
                <h3 class="user-name">Rani Das</h3>
                <span>Mumbai</span>
              </div>
            </div>
          </div>
        </div>
        <!-- ***** Testimonials Item End ***** -->

        <!-- ***** Testimonials Item Start ***** -->
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="team-item">
            <div class="team-content">
              <i><img src="5.png" alt=""></i>
              <p>Genus Coffea's 'Monsoon Malabar' is a revelation! The smooth, chocolatey flavor is unlike anything I've
                tasted before. Perfect for a relaxing evening cup.</p>
              <div class="user-image">
                <img src="dp2.png" style="width: 60px; height: 60px;" alt="">
              </div>
              <div class="team-info">
                <h3 class="user-name">Akhil Sharma</h3>
                <span>Bangalore</span>
              </div>
            </div>
          </div>
        </div>
        <!-- ***** Testimonials Item End ***** -->

        <!-- ***** Testimonials Item Start ***** -->
        <div class="col-lg-4 col-md-6 col-sm-12">
          <div class="team-item">
            <div class="team-content">
              <i><img src="5.png" alt=""></i>
              <p>Finally found a decaf option that doesn't compromise on taste! Genus Coffea's 'Swiss Water Decaf' is a
                lifesaver for my afternoon cravings. Guilt-free and delicious!</p>
              <div class="user-image">
                <img src="dp3.png" style="width: 60px; height: 60px;" alt="">
              </div>
              <div class="team-info">
                <h3 class="user-name">Priya Singh</h3>
                <span>Delhi</span>
              </div>
            </div>
          </div>
        </div>
        <!-- ***** Testimonials Item End ***** -->
      </div>
    </div>
  </section>
  <!-- ***** Testimonials End ***** -->


  <!-- ***** Contact Us Start ***** -->
  <section class="section colored" id="contact-us">
    <div class="container">
      <!-- ***** Section Title Start ***** -->
      <div class="row">
        <div class="col-lg-12">
          <div class="center-heading">
            <h2 class="section-title">Talk To Us</h2>
          </div>
        </div>
        <div class="offset-lg-3 col-lg-6">
          <div class="center-text">
            <p>Ready to elevate your coffee experience? Talk to us about your coffee needs.</p>
          </div>
        </div>
      </div>
      <!-- ***** Section Title End ***** -->

      <div class="row">
        <!-- ***** Contact Text Start ***** -->
        <div class="col-lg-4 col-md-6 col-sm-12">
          <h5 class="margin-bottom-30">Keep in touch</h5>
          <div class="contact-text">
            <p>Genus Coffea, XXXXXXX, XXXXX, XXXX
              <br>XXXXX, Kerala, India
              <br>
              Mobile Number : +91 XXXXXXXXXX
            
            <br>
              Email ID : Sales@genuscoffea.in
            </p>
          </div>
        </div>
        <!-- ***** Contact Text End ***** -->

        <!-- ***** Contact Form Start ***** -->
        <div class="col-lg-8 col-md-6 col-sm-12">
          <div class="contact-form">
            <form id="contact" @submit.prevent="handleClick" method="get">
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <fieldset>
                    <input name="name" type="text" v-model="formData.name" class="form-control" id="name" placeholder="Full Name" required="">
                  </fieldset>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                  <fieldset>
                    <input name="email" type="email" v-model="formData.email" class="form-control" id="email" placeholder="E-Mail Address"
                      required="">
                  </fieldset>
                </div>
                <div class="col-lg-12">
                  <fieldset>
                    <textarea name="message" rows="6" v-model="formData.message" class="form-control" id="message" placeholder="Your Message"
                      required=""></textarea>
                  </fieldset>
                </div>
                <div class="col-lg-12">
                  <fieldset>
                    <ButtonStyle1 button="Send Message" type="submit" id="form-submit" class="main-button"></ButtonStyle1>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <!-- ***** Contact Form End ***** -->
      </div>
    </div>
  </section>
  <!-- ***** Contact Us End ***** -->

  <!-- ***** Footer Start ***** -->
  <footer>
    <div class="container">
      <div class="row">
      </div>
      <div class="row">
        <div class="col-lg-12">
          <p class="copyright">Copyright &copy; 2024 Genus Coffea | Designed By: <b>Himanshu Patel &#9763;</b></p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import ButtonStyle1 from './ButtonStyle1.vue';
import ButtonStyle2 from './ButtonStyle2.vue';


export default {
  name: 'HomeBody',
  components: {
    ButtonStyle1,
    ButtonStyle2
  },
  data() {
    return {
      formData: {
        name: '',
        email: '',
        message: '',
      },
    };
  },
  mounted() {
    // Window Resize Mobile Menu Fix
    this.mobileNav();

    "use strict";

    // Window Resize Mobile Menu Fix
    mobileNav();


    // Scroll animation init
    window.sr = new scrollReveal();


    // Menu Dropdown Toggle
    if ($('.menu-trigger').length) {
      $(".menu-trigger").on('click', function () {
        $(this).toggleClass('active');
        $('.header-area .nav').slideToggle(200);
      });
    }


    // Menu elevator animation
    $('a[href*=\\#]:not([href=\\#])').on('click', function () {
      if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        if (target.length) {
          var width = $(window).width();
          if (width < 991) {
            $('.menu-trigger').removeClass('active');
            $('.header-area .nav').slideUp(200);
          }
          $('html,body').animate({
            scrollTop: (target.offset().top) - 130
          }, 700);
          return false;
        }
      }
    });

    $(document).ready(function () {
      $(document).on("scroll", onScroll);

      //smoothscroll
      $('a[href^="#"]').on('click', function (e) {
        e.preventDefault();
        $(document).off("scroll");

        $('a').each(function () {
          $(this).removeClass('active');
        })
        $(this).addClass('active');

        var target = this.hash,
          menu = target;
        var target = $(this.hash);
        $('html, body').stop().animate({
          scrollTop: (target.offset().top) - 130
        }, 500, 'swing', function () {
          window.location.hash = "";
          $(document).on("scroll", onScroll);
        });
      });
    });

    function onScroll(event) {
      var scrollPos = $(document).scrollTop();
      $('.nav a').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.attr("href"));
        if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
          $('.nav ul li a').removeClass("active");
          currLink.addClass("active");
        }
        else {
          currLink.removeClass("active");
        }
      });
    }


    // Home seperator
    if ($('.home-seperator').length) {
      $('.home-seperator .left-item, .home-seperator .right-item').imgfix();
    }


    // Home number counterup
    if ($('.count-item').length) {
      $('.count-item strong').counterUp({
        delay: 10,
        time: 1000
      });
    }


    // Page loading animation
    $(window).on('load', function () {
      if ($('.cover').length) {
        $('.cover').parallax({
          imageSrc: $('.cover').data('image'),
          zIndex: '1'
        });
      }

      $("#preloader").animate({
        'opacity': '0'
      }, 600, function () {
        setTimeout(function () {
          $("#preloader").css("visibility", "hidden").fadeOut();
        }, 300);
      });
    });


    // Window Resize Mobile Menu Fix
    $(window).on('resize', function () {
      mobileNav();
    });


    // Window Resize Mobile Menu Fix
    function mobileNav() {
      var width = $(window).width();
      $('.submenu').on('click', function () {
        if (width < 992) {
          $('.submenu ul').removeClass('active');
          $(this).find('ul').toggleClass('active');
        }
      });
    }
  },
  methods: {
    mobileNav() {
      var width = window.innerWidth;
      $('.submenu').on('click', function () {
        if (width < 992) {
          $('.submenu ul').removeClass('active');
          $(this).find('ul').toggleClass('active');
        }
      });
    },
    handleClick() {
      const recipient = 'sales@genuscoffea.in';
      const subject = 'Message from Genus Coffea Website';
      const body = `Name: ${this.formData.name}\nEmail: ${this.formData.email}\nMessage: ${this.formData.message}`;

      // Construct the mailto link with encoded values
      const encodedBody = encodeURIComponent(body);
      window.location.href = `mailto:${recipient}?subject=${subject}&body=${encodedBody}`;

      // Clear the form after successful submission
      this.formData.name = '';
      this.formData.email = '';
      this.formData.message = '';
    }
  }
}
</script>
